let form = document.querySelector('.shopify-product-form[action="/cart/add"]');

if(form) {
  let btn = form.querySelector('button[type="submit"]');
  if(btn) {
    btn.addEventListener('click', () => {
      let spinner = btn.querySelector('.loading__spinner');
      if(spinner) {
        spinner.classList.remove('hidden');
      }
    })
  }
}